<div class="login-container">
  <img
    src="login/corner-logo.png"
    class="logo"
  />
  <div class="widget-container">
    <div id="okta-signin-container"></div>
  </div>

  <div
    class="load"
    *ngIf="loading"
  >
    <img
      title="loading"
      class="loading-logo"
      src="/assets/sustainment-icons/sustain-icon-white.png"
    />
  </div>
</div>

<p-confirmDialog
  [key]="'emailValidationConfirm'"
  [closable]="true"
  [baseZIndex]="10000"
  styleClass="dialog-sm"
></p-confirmDialog>
