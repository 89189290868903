import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import {
  IBookmark,
  IBookmarkList,
  PaginatedList,
  SupplierListModel
} from 'sustainment-models';
import { BookmarkStore } from './bookmark.store';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  map,
  Observable,
  ReplaySubject,
  switchMap
} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BookmarkQuery extends Query<IBookmarkList> {
  private _selectedBookmark = new ReplaySubject<string>(1);
  private _pagination = new BehaviorSubject<{
    pageSize: number;
    pageIndex: number;
  }>({ pageIndex: 1, pageSize: 10 });
  private _textFilter = new BehaviorSubject<string>('');

  public paginatedBookmark$ = this.select((x) => x.bookmarks).pipe(
    filter((x) => !!x?.length),
    switchMap((x) => this.createPaginatedStream(x))
  );

  public constructor(protected store: BookmarkStore) {
    super(store);
  }

  private createPaginatedStream(
    data: IBookmark[]
  ): Observable<PaginatedList<SupplierListModel>> {
    return combineLatest([
      this._selectedBookmark,
      this._pagination,
      this._textFilter
    ]).pipe(
      map(([selectedBookmark, { pageIndex, pageSize }, textFilter]) => {
        const bookmark = data.find((x) => x.collectionId === selectedBookmark);
        let selectedArray = bookmark!.favorites?.filter((x) => !!x.vendor);
        if (textFilter) {
          selectedArray = selectedArray!.filter((item) =>
            item.vendor?.name.toLowerCase().includes(textFilter)
          );
        }
        const totalRecords = selectedArray!.length;
        const totalPages = Math.ceil(totalRecords / pageSize);

        const startIndex = (pageIndex - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const pageData = selectedArray!.slice(startIndex, endIndex);
        return {
          pageIndex,
          pageSize,
          totalPages,
          hasPreviousPage: pageIndex > 1,
          hasNextPage: pageIndex < totalPages,
          totalRecords,
          items: pageData.map((x) => x.vendor!),
          bookmark: {
            name: bookmark?.name,
            description: bookmark?.description,
            collectionId: bookmark?.collectionId
          }
        };
      })
    );
  }

  public setPage(pageIndex: number, pageSize: number): void {
    this._pagination.next({ pageIndex, pageSize });
  }

  public setTextFilter(filter: string): void {
    this._textFilter.next(filter.trim().toLowerCase());
  }

  public selectBookmark(id: string): void {
    this._selectedBookmark.next(id);
  }
}
